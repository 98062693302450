import { FiInstagram, FiList, FiUser } from "react-icons/fi";
import {FiMessageSquare, FiPhoneCall} from "react-icons/fi"

import {HiOutlineMailOpen} from "react-icons/hi"
import Image from "../elements/image";
import Img from "gatsby-image";
import Layout from "../components/layout";
import React from 'react';
import bi1 from "../data/images/boaticons/1.png"
import bi2 from "../data/images/boaticons/2.png"
import bi3 from "../data/images/boaticons/3.png"
import bi4 from "../data/images/boaticons/4.png"
import bi5 from "../data/images/boaticons/5.png"
import bi6 from "../data/images/boaticons/6.png"
import bi7 from "../data/images/boaticons/7.png"
import bi8 from "../data/images/boaticons/8.png"
import bi9 from "../data/images/boaticons/9.png"

const ProjectDetails = ({data}) => {
    const projectData = data.projectJson;
    const projectImage = data.projectJson.features;
    return (
        <Layout>
            <div className="rn-project-details-area rn-section-gap bg-color-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <div className="portfolio-content">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="content-left">
                                                <div className="page-top">
                                                    <h1 className="title_holder">{projectData.title}</h1>
                                                </div>
                                                <h3 className="mt--20">Details</h3>
                                                {/* <ul className="list_holder">
                                                    <li><span className="icon"><FiList />Category:</span><span className="projectinfo">{projectData.category}</span></li>
                                                    <li><span className="icon"><FiUser />Client:</span><span className="projectinfo">{projectData.client}</span></li>
                                                    <li><span className="icon"><FiInstagram />Images by:</span><span className="projectinfo">{projectData.imgesBY}</span></li>
                                                </ul> */}

                                                <div className="row mb--10" style={{borderBottom: "1px solid rgba(0, 0, 0, 0.2)"}}>
                                                    <div className="col-lg-12 col-md-12 col-xs-12">

                                                        
                                                    <h6 className="subtitle" style={{textAlign: "left"}}><strong>{projectData.season1}</strong> per day</h6>
                                                        <h6 className="subtitle" style={{textAlign: "left"}}><strong>{projectData.season2}</strong> per day</h6>
                                                        <h6 className="subtitle" style={{textAlign: "left"}}><strong>{projectData.season3}</strong> per day</h6>
                                                    
                                                    </div>
                                                </div>

                                                <div className="row mb--10" style={{borderBottom: "1px solid rgba(0, 0, 0, 0.2)"}}>

                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                        <div className="thumbnail-inner">
                                                            <img src={bi1} alt="boat icon length" width={48} /><br /><small>{projectData.bl}</small>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                        <div className="thumbnail-inner">
                                                            <img src={bi2} alt="boat icon width" width={48}/><br /><small>{projectData.bw}</small>
                                                        </div>

                                                    </div>

                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                            <div className="thumbnail-inner">
                                                                <img src={bi3} alt="boat icon persons" width={48}/><br /><small>{projectData.pax}</small>
                                                            </div>

                                                    </div>

                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                        <div className="thumbnail-inner">
                                                            <img src={bi4} alt="boat icon weight" width={48}/><br /><small>{projectData.kg}</small>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                        <div className="thumbnail-inner">
                                                            <img src={bi5} alt="boat icon fuel in liters" width={48}/><br /><small>{projectData.fuel}</small>
                                                        </div>

                                                    </div>

                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                            <div className="thumbnail-inner">
                                                                <img src={bi6} alt="boat icon water in liters" width={48}/><br /><small>{projectData.water}</small>
                                                            </div>

                                                    </div>

                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                        <div className="thumbnail-inner">
                                                            <img src={bi7} alt="boat icon category" width={48}/><br /><small>{projectData.ce}</small>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                        <div className="thumbnail-inner">
                                                            <img src={bi8} alt="boat icon speed" width={48}/><br /><small>{projectData.speed}</small>
                                                        </div>

                                                    </div>

                                                    <div className="col-lg-2 col-md-2 col-6" style={{textAlign: "center"}}>

                                                            <div className="thumbnail-inner">
                                                                <img src={bi9} alt="boat icon engine" width={48}/><br /><small>{projectData.engine}</small>
                                                            </div>

                                                    </div>

                                                    <div className="col-lg-12 col-md-12 col-12" style={{height: "10px"}}></div>

                                                </div>

                                                {/* <p className="mt--20">{projectData.body}</p> */}
                                                <div className="row mb--10">
                                                    <div className="col-lg-6 col-md-6 col-12 mt--10">
                                                        <div style={{textAlign: "center", background: "#3aaae1", padding: "5px", border: "1px solid gray", borderRadius: "8px"}}>
                                                            <span className="category"><a href="tel:+385915805907" style={{color: "white"}}> <FiPhoneCall size={28}/> Call Us</a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12 mt--10">
                                                    <div style={{textAlign: "center", background: "#FFFFFF", padding: "5px", border: "1px solid gray", borderRadius: "8px"}}>
                                                            <span className="category"><a href="mailto:krcina28@yahoo.com" style={{color: "black"}}> <HiOutlineMailOpen size={28}/> Send us email</a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="thumbnail mt--90 mt_md--40 mt_sm--40">
                                    <Image fluid={projectData.featured_image.childImageSharp.fluid} />
                                </div>

                                <div className="image-group">
                                    {projectImage.map((data, index) => (
                                        <div className="single-image mt--30" key={index}>
                                            <Img fluid={data.image.childImageSharp.fluid} alt={"Sole Nautica - " + projectData.title + "-" + index}/>
                                        </div>
                                    ))}
                                </div>   

                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql `
    query($id: String!) {
        projectJson(id: {eq: $id}) {
            id
            title
            body
            category
            bl
            bw
            ce
            pax
            kg
            fuel
            water
            engine
            speed
            pricefrom
            client
            imgesBY
            season1
            season2
            season3
            featured_image {
                childImageSharp {
                    fluid(maxHeight: 1000, maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationHeight
                        presentationWidth
                    }
                }
            },
            features {
                image {
                    childImageSharp {
                      fluid(maxWidth: 1920, maxHeight: 1280, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                      }
                    }
                }
            }
            
        }
    }
`;
export default ProjectDetails;